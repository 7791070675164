.mdc-text-field-helper-text--validation-msg {
  color: #b00020 !important;
}

.mdc-text-field-helper-text {
  padding: 4px 0 12px;
  height: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.mdc-text-field-helper-text::before {
  display: none !important;
}