.app-search-field {
  .mdc-text-field__icon--trailing {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.mdc-text-field--has-value {
    .mdc-text-field__icon--trailing {
      opacity: 1;
    }
  }
}