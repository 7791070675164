@import '~@woar/core/styles/colors';
@import '~@woar/core/styles/spacing';

.form-layout {
  .mdc-button {
    margin: 0 8px;
  }
}

.form-field {
  margin: 8px 0;

  .mdc-text-field{
    width: 100%;
  }

  .mdc-text-field-helper-text--show {
    height: 15px;
    opacity: 1 !important;
  }
}

.form-actions {
  margin: 8px 0;
}

.form-footer {
  margin: $spacing-lg 0;

  .mdc-button--dense {
    height: 28px;
    font-size: 0.75rem;
  }

  .form-footer__action {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: flex-end;
    align-items: center;
    margin: 4px 0;
  }
}

.form-message {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid transparent;
  margin: $spacing-lg 0;
  padding: $spacing-md;

  i {
    margin-right: $spacing-sm;
  }
}

.form-error {
  border: 2px solid $error;

  i {
    color: $error;
    margin-right: $spacing-sm;
  }
  p {
    color: $error;
  }
}