@function calculate-spacing($baseline, $factor) {
  $spacing: $baseline * $factor;
  @return #{$spacing}px;
}

$spacing-baseline: 8;
$spacing-hair: calculate-spacing($spacing-baseline, 0.125); // 1
$spacing-nano: calculate-spacing($spacing-baseline, 0.25); // 2
$spacing-xs: calculate-spacing($spacing-baseline, 0.5); // 4
$spacing-sm: calculate-spacing($spacing-baseline, 1); // 8
$spacing-md: calculate-spacing($spacing-baseline, 2); // 16
$spacing-lg: calculate-spacing($spacing-baseline, 3); // 24
$spacing-xl: calculate-spacing($spacing-baseline, 4); // 32
