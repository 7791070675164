/**
 * Color Schema: https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=9378ff&secondary.color=FFF078
 */
/* Purple */
//$primary: #9378ff;
//$primary-light: #c8a7ff;
//$primary-dark: #5e4bcb;
///* Yellow */
//$secondary: #fff078;
//$secondary-light: #ffffa9;
//$secondary-dark: #cabe48;

/**
 * Color Schema: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=212121&secondary.color=FAFAFA
 */
/* Black */
$primary: #212121;
$primary-light: #484848;
$primary-dark: #000000;
/* White */
$secondary: #fafafa;
$secondary-light: #ffffff;
$secondary-dark: #c7c7c7;

/* Generics */
$white: #ffffff;
$black: #000000;
$error: #b00020;
$success: #00b020;
$text-color: #222222;

:export {
  primary: $primary;
  primaryLight: $primary-light;
  primaryDark: $primary-dark;
  secondary: $secondary;
  secondaryLight: $secondary-light;
  secondaryDark: $secondary-dark;
  white: $white;
  black: $black;
}
